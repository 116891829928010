<template>
  <div class="qy_query_log" v-loading="loading" element-loading-text="拼命加载中">
    <div class="cut">
      <el-button-group>
        <el-button class="btn" size="small" type="primary" icon="el-icon-edit-outline" @click="cut(1)">管理员操作日志</el-button>
        <el-button type="primary" size="small" icon="el-icon-share" @click="cut(2)">登录日志</el-button>
      </el-button-group>
    </div>
    <!-- 管理员操作日志 -->
    <div class="content" v-if="isCut">
      <el-table
        ref="multipleTable"
        :data="systemData"
        tooltip-effect="dark"
        max-height="750"
        style="width: 100%"
        @selection-change="handleSelectionChange"
        stripe
        border
        class="tableBox"
      >
        <el-table-column prop="ip" label="IP地址" width="300" align="center"></el-table-column>
        <el-table-column prop="title" label="操作内容" align="center"></el-table-column>
      </el-table>
    </div>
    <!-- 用户登录日志 -->
    <div class="content" v-if="!isCut">
      <el-table
        ref="multipleTable"
        :data="userLogData2"
        tooltip-effect="dark"
        max-height="750"
        style="width: 100%"
        @selection-change="handleSelectionChange"
        stripe
        border
        class="tableBox"
      >
        <el-table-column prop="login_ip" label="IP地址" width="300" align="center"></el-table-column>
        <el-table-column prop="account" label="账号" align="center"></el-table-column>
        <el-table-column prop="login_time" label="时间" align="center">
          <template slot-scope="scope">
            <span>{{scope.row.login_time}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="error_msg" label="内容" align="center"></el-table-column>
      </el-table>
    </div>
    <!-- 页码 -->
    <div class="page" v-if="ispage">
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="1"
        :page-size="size"
        layout="total, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
import { queryLog } from "@/api/comm.js";
export default {
  data() {
    return {
      loading: true,
      ispage: false,
      isCut: true,
      systemData: [],
      userLogData2: [],
      size: 20,
      total: 0,
      issystemNum: 0,
      isuserLogNum: 0,
      page: 1,
    };
  },
  created() {
    this.loadList();
  },
  methods: {
    handleCurrentChange(val) {
      this.page = val;
      this.loadList();
    },
    handleSelectionChange() {},
    loadList() {
      let data = {
        page: this.page,
        pagesize: this.size,
      };
      queryLog(data).then((res) => {
        if (res.code == 400200) {
          this.systemData = res.data.system.data;
          this.userLogData2 = res.data.userLog.data;
          this.issystemNum = res.data.system.count;
          this.isuserLogNum = res.data.userLog.count;
          if (this.isCut) {
            this.total = this.issystemNum;
          } else {
            this.total = this.isuserLogNum;
          }
          this.ispage = true;
          this.loading = false;
        }
      });
    },
    cut(val) {
      this.loading = true;
      this.ispage = false;
      this.page = 1;
      if (val == 1) {
        this.isCut = true;
      }
      if (val == 2) {
        this.isCut = false;
      }
      this.loadList();
    },
  },
};
</script>
<style lang="less">
.qy_query_log {
  .cut {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
    .btn{
      margin-right: 10px;
    }
  }
  //   表格
  .content {
    position: relative;
    height: calc(~"100% - 220px");
    min-height: 750px;
    margin-top: 20px;
    color: rgb(4, 87, 102);
    th {
      color: rgb(4, 87, 102);
      padding: 0;
      height: 40px;
      line-height: 40px;
      background: rgb(217, 242, 247);
    }
    td {
      padding: 0;
      height: 35px;
      line-height: 35px;
    }
    tr.el-table__row--striped td {
      background: rgb(234, 246, 248);
    }
    .el-button + .el-button {
      margin-left: 0px;
    }
    .el-button--mini {
      padding: 4px 4px;
      background: transparent;
      color: rgb(4, 87, 102);
      font-size: 14px;
      &:hover {
        border: 1px solid rgb(105, 105, 105);
      }
    }
    .no,
    .off {
      cursor: pointer;
    }
    .no {
      color: green;
    }
    .off {
      color: red;
    }
    .el-input__inner {
      width: 50px;
      padding: 5px;
      text-align: center;
    }
  }
  .page {
    // .el-pagination {
    //   position: absolute;
    //   bottom: 0;
    //   right: 0;
    // }
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }
}
// 带牌表格样式
table{
  color: #100700;
    th {
      color: #4B2000 !important;
      background: #FFDEC6 !important;
      border-right: none !important;
      border-left: none !important;
    }
    td {
      border-right: none !important;
      border-left: none !important;
    }
    .el-button--mini{
      color: #FFAB71 !important;
      padding: 4px 4px;
      background: transparent;
      border: none !important;
      font-size: 14px;
    }
}
</style>